// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/IoZsjeSka";

const enabledGestures = {BH3_qbkK5: {hover: true}};

const cycleOrder = ["BH3_qbkK5"];

const serializationHash = "framer-zw69K"

const variantClassNames = {BH3_qbkK5: "framer-v-5bq0jo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const suffix = (value, suffix) => {
    if (typeof value === "string" && typeof suffix === "string") {
        return value + suffix;
    } else if (typeof value === "string") {
        return value;
    } else if (typeof suffix === "string") {
        return suffix;
    }
    return "";
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({firstName, height, id, image, lastName, link, width, ...props}) => { return {...props, RM29yW9jL: image ?? props.RM29yW9jL, vJIoSdg2D: firstName ?? props.vJIoSdg2D ?? "First", wkV2zOOya: link ?? props.wkV2zOOya, y95p_3Xga: lastName ?? props.y95p_3Xga ?? "Last"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};firstName?: string;lastName?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RM29yW9jL, vJIoSdg2D, y95p_3Xga, wkV2zOOya, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BH3_qbkK5", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const textContent = suffix(suffix(vJIoSdg2D, " "), y95p_3Xga)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={wkV2zOOya} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-5bq0jo", className, classNames)} framer-1a9d5yw`} data-framer-name={"card"} layoutDependency={layoutDependency} layoutId={"BH3_qbkK5"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"BH3_qbkK5-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "48px", ...toResponsiveImage(RM29yW9jL)}} className={"framer-ebk1pq"} data-framer-name={"img"} layoutDependency={layoutDependency} layoutId={"En1_J_whF"} style={{borderBottomLeftRadius: 80, borderBottomRightRadius: 80, borderTopLeftRadius: 80, borderTopRightRadius: 80}}/><motion.div className={"framer-1npv0gs"} data-framer-name={"text"} layoutDependency={layoutDependency} layoutId={"ViD15A3Cc"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-13xf2c2"} data-styles-preset={"IoZsjeSka"}> </motion.p></React.Fragment>} className={"framer-1ks9vs6"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"D41JdiBbS"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={textContent} variants={{"BH3_qbkK5-hover": {"--extracted-r6o4lv": "var(--token-50609bf5-d148-4668-bef9-df367545520e, rgb(204, 95, 50))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"BH3_qbkK5-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-13xf2c2"} data-styles-preset={"IoZsjeSka"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-50609bf5-d148-4668-bef9-df367545520e, rgb(204, 95, 50)))"}}> </motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zw69K.framer-1a9d5yw, .framer-zw69K .framer-1a9d5yw { display: block; }", ".framer-zw69K.framer-5bq0jo { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 250px; }", ".framer-zw69K .framer-ebk1pq { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 48px); overflow: hidden; position: relative; width: 48px; will-change: var(--framer-will-change-override, transform); }", ".framer-zw69K .framer-1npv0gs { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 1px; }", ".framer-zw69K .framer-1ks9vs6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zw69K.framer-5bq0jo, .framer-zw69K .framer-1npv0gs { gap: 0px; } .framer-zw69K.framer-5bq0jo > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-zw69K.framer-5bq0jo > :first-child, .framer-zw69K .framer-1npv0gs > :first-child { margin-left: 0px; } .framer-zw69K.framer-5bq0jo > :last-child, .framer-zw69K .framer-1npv0gs > :last-child { margin-right: 0px; } .framer-zw69K .framer-1npv0gs > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 250
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"i0NJZ6MRt":{"layout":["fixed","auto"]}}}
 * @framerVariables {"RM29yW9jL":"image","vJIoSdg2D":"firstName","y95p_3Xga":"lastName","wkV2zOOya":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLKJaN4huD: React.ComponentType<Props> = withCSS(Component, css, "framer-zw69K") as typeof Component;
export default FramerLKJaN4huD;

FramerLKJaN4huD.displayName = "navigation / team";

FramerLKJaN4huD.defaultProps = {height: 48, width: 250};

addPropertyControls(FramerLKJaN4huD, {RM29yW9jL: {title: "Image", type: ControlType.ResponsiveImage}, vJIoSdg2D: {defaultValue: "First", displayTextArea: false, placeholder: "First name", title: "First name", type: ControlType.String}, y95p_3Xga: {defaultValue: "Last", displayTextArea: false, placeholder: "Last name", title: "Last name", type: ControlType.String}, wkV2zOOya: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerLKJaN4huD, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})